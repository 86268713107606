import { gql } from '~/types/graphql/gql';

export const QUERY_JOB_SPECIALIZATIONS = gql(`
  query jobSpecializations($pagination: Pagination!, $name: String) {
    jobSpecializations: epJobSpecializations(
      pagination: $pagination
      name: $name
    ) {
      list {
        id
        name
        displayName
      }
    }
  }
`);

export const QUERY_JOB_SPECIALIZATION_ROLES = gql(`
  query jobSpecializationRoles(
    $jobSpecializationId: ID
    $name: String
    $pagination: Pagination
    $priority: EpJobSpecializationRolesPriority
    $positionName: String
  ) {
    jobSpecializationRoles: epJobSpecializationRoles(
      jobSpecializationId: $jobSpecializationId
      name: $name
      pagination: $pagination
      priority: $priority
      positionName: $positionName
    ) {
      list {
        id
        displayName
        groupBy
        groupByStr
        isRecommended
        jobRole {
          displayName
          id
          jobSpecializationId
          name
        }
        jobSpecialization {
          displayName
          id
          name
        }
        name
      }
      elements
      hasNextPage
      hasPrevPage
      limit
      page
      size
    }
  }
`);

export const QUERY_JOB_SPECIALIZATION_ROLE_SKILLS = gql(`
  query jobSpecializationRoleSkills(
    $excludeIds: [ID]
    $jobSpecializationRoleId: ID
    $name: String
    $pagination: Pagination
    $priority: EpJobSpecializationRoleSkillsPriority
  ) {
    jobSpecializationRoleSkills: epJobSpecializationRoleSkills(
      excludeIds: $excludeIds
      jobSpecializationRoleId: $jobSpecializationRoleId
      name: $name
      pagination: $pagination
      priority: $priority
    ) {
      list {
        displayName
        groupBy
        groupByStr
        id
        name
      }
      elements
      hasNextPage
      hasPrevPage
      limit
      page
      size
    }
  }
`);

export const QUERY_JOB_VACANCY_LEVEL_DROPDROWN = gql(`
  query jobVacancyLevelDropdown {
    jobVacancyLevelDropdown: epJobVacancyLevelDropdown {
      list {
        key
        value
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_TYPE_DROPDROWN = gql(`
  query jobVacancyTypeDropdown {
    jobVacancyTypeDropdown: epJobVacancyTypeDropdown {
      list {
        key
        value
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_LOCATION_SITE_DROPDOWN = gql(`
  query jobVacancyLocationSiteDropdown {
    jobVacancyLocationSiteDropdown: epJobVacancyLocationSiteDropdown {
      list {
        key
        value
        description
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_CONTACT_TYPE_DROPDOWN = gql(`
  query jobVacancyContactTypeDropdown {
    jobVacancyContactTypeDropdown: epJobVacancyContactTypeDropdown {
      value
      key
    }
  }
`);

export const QUERY_EDUCATION_LEVEL_DROPDOWN = gql(`
  query educationLevelDropdown {
    educationLevelDropdown: epEducationLevelDropdown {
      key
      value
    }
  }
`);

export const QUERY_EDUCATION_PROGRAMS = gql(`
  query epEducationPrograms($level: EpEducationLevel, $name: String, $pagination: Pagination) {
    educationPrograms: epEducationPrograms(level: $level, name: $name, pagination: $pagination) {
      elements
      hasNextPage
      hasPrevPage
      limit
      list {
        id
        name
      }
      page
      size
    }
  }
`);

export const QUERY_EDUCATION_INSTITUTIONS = gql(`
  query educationInstitutions($name: String, $pagination: Pagination) {
    educationInstitutions: epEducationInstitutions(
      name: $name
      pagination: $pagination
    ) {
      elements
      hasNextPage
      hasPrevPage
      limit
      list {
        id
        name
        displayName
        rank
      }
      page
      size
    }
  }
`);

export const QUERY_GENDER_DROPDOWN = gql(`
  query genderDropdown {
    genderDropdown: epGenderDropdown {
      key
      value
    }
  }
`);

export const QUERY_LOCATION_PROVINCES = gql(`
  query locationProvinces($name: String, $hasVacancy: Boolean) {
    locationProvinces: epLocationProvinces(
      name: $name
      hasVacancy: $hasVacancy
    ) {
      id
      name
    }
  }
`);

export const QUERY_LOCATION_CITIES = gql(`
  query locationCities($name: String, $parentId: ID, $hasVacancy: Boolean) {
    locationCities: epLocationCities(
      name: $name
      parentId: $parentId
      hasVacancy: $hasVacancy
    ) {
      id
      name
    }
  }
`);

export const QUERY_LOCATION_DISTRICTS = gql(`
  query locationDistrics($name: String, $parentId: ID, $hasVacancy: Boolean) {
    locationDistrics: epLocationDistrics(
      name: $name
      parentId: $parentId
      hasVacancy: $hasVacancy
    ) {
      id
      name
    }
  }
`);

export const QUERY_LOCATION_CITY_DETAIL = gql(`
  query locationDetail($locationId: ID!) {
    locationCityDetail: epLocationDetail(locationId: $locationId) {
      id
      parentId
      name
      level
      hasVacancy
    }
  }
`);

export const QUERY_COMPANY_SIZES = gql(`
  query companySizes {
    companySizes: epCompanySizes(filter: { page: 0, limit: 99 }) {
      id
      name
      size
    }
  }
`);

export const QUERY_COMPANY_INDUSTRIES = gql(`
  query companyIndustries {
    companyIndustries: epCompanyIndustries(filter: { page: 0, limit: 99 }) {
      list {
        id
        name
      }
    }
  }
`);

export const QUERY_USERS = gql(`
  query users {
    users: epUsers {
      username
      companyId
    }
  }
`);

export const QUERY_USER_CONFIR_EMAIL_NOTIFICATION_INTERVAL_DROPDOWN = gql(`
  query userConfigEmailNotificationIntervalDropdown {
    userConfigEmailNotificationIntervalDropdown: epUserConfigEmailNotificationIntervalDropdown {
      key
      value
      description
    }
  }
`);

export const QUERY_EXPERIENCE_YEAR_DROPDOWN = gql(`
  query experienceYearDropdown {
    experienceYearDropdown: epExperienceYearDropdown {
      key
      value
    }
  }
`);

export const QUERY_EXPERIENCE_JOB_FUNCTIONS = gql(`
  query experienceJobFunctions($filter: CommonFilter, $name: String) {
    experienceJobFunctions: epExperienceJobFunctions(
      filter: $filter
      name: $name
    ) {
      elements
      page
      list {
        key
        value
      }
    }
  }
`);

export const QUERY_USER_SKILLS = gql(`
  query userSkills($filter: CommonFilter, $name: String) {
    userSkills: epUserSkills(filter: $filter, name: $name) {
      elements
      page
      list {
        key
        value
      }
    }
  }
`);

export const QUERY_LOCATION_AREAS = gql(`
  query locationAreas($filter: CommonFilter, $name: String) {
    locationAreas: epLocationAreas(filter: $filter, name: $name) {
      page
      elements
      list {
        name
        displayName
        type
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_BENEFITS = gql(`
  query jobVacancyBenefits {
    jobVacancyBenefits: epJobVacancyBenefits {
      key
      value
    }
  }
`);

export const QUERY_MENU_TABS = gql(`
  query menuTabs($domain: EpMenuTabDomain!) {
    menuTabs: epMenuTabs(domain: $domain) {
      id
      parentId
      domain
      name
      displayName
      textColor
      bgColor
      isLock
      iconUrl
      message
      isDisplayLink
      badgeLabel
      nextLevelMenuTab {
        id
        parentId
        domain
        name
        displayName
        textColor
        bgColor
        countTextColor
        countBgColor
        description
        tooltipDescription
      }
    }
  }
`);

export const QUERY_DOCUMENTS_CERTIFICATES = gql(`
  query documentsCertificates($pagination: Pagination, $name: String) {
    documentsCertificates: epDocumentsCertificates(
      pagination: $pagination
      name: $name
    ) {
      list {
        id
        name
        displayName
      }
      elements
      hasNextPage
      hasPrevPage
      limit
      page
      size
    }
  }
`);

export const QUERY_JOB_VACANCY_POSITION_NAMES = gql(`
  query jobVacancyPositionNames($name: String, $pagination: Pagination) {
    jobVacancyPositionNames: epJobVacancyPositionNames(
      name: $name
      pagination: $pagination
    ) {
      list {
        id
        name
        displayName
      }
      elements
      hasNextPage
      hasPrevPage
      isSuccess
      limit
      page
      size
    }
  }
`);

export const QUERY_INVITATION_STATUS_DROPDOWN = gql(`
  query invitationStatusDropdown {
    invitationStatusDropdown: epInvitationStatusDropdown {
      id
      name
    }
  }
`);
