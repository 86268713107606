import {
  Box,
  brandColors,
  Image,
  Stack,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ConsLocationIcon from '~/components/ConsComponents/Icons/ConsLocationIcon';
import ConsGajiIcon from '~/components/ConsComponents/Icons/ConsGajiIcon';
import ConsSchoolIcon from '~/components/ConsComponents/Icons/ConsSchoolIcon';
import { formatToIDR } from '~/utils/currency';
import { formatStringURLToHTML } from '~/utils/helper';
import {
  CandidateInviteEmailTemplateProps,
  CandidateInviteEmailTemplateVacancyInfo,
} from './CandidateInviteEmailTemplate.types';

const CandidateInviteEmailTemplate = ({
  templateContent,
  company,
  vacancy,
  ...props
}: CandidateInviteEmailTemplateProps) => {
  const { t } = useTranslation();

  const formatTemplateContent = useMemo(() => {
    return formatStringURLToHTML(
      templateContent,
      ({ text, url }) =>
        `<a href="${url}" style="pointer-events: none">${text}</a>`,
    );
  }, [templateContent]);

  const locationStr = useMemo(() => {
    const { locationProvince: province, locationCity: city } = vacancy;

    if (!province && !city) return null;
    if (province && city) return `${city}, ${province}`;
    return province || city;
  }, [vacancy]);

  const educationStr = useMemo(() => {
    const { educationLevelStr: education } = vacancy;

    return (
      education &&
      t('candidate:emailInvitationPreview.minimumEducation', {
        education: education,
      })
    );
  }, [vacancy]);

  const salaryStr = useMemo(() => {
    const { salaryLowerBound: lower, salaryUpperBound: upper } = vacancy;

    if (!lower && !upper) return null;
    if (lower && upper)
      return `${formatToIDR(lower, true)} - ${formatToIDR(upper, true)}`;
    if (lower) return formatToIDR(lower, true);
    if (upper) return formatToIDR(upper, true);
  }, [vacancy]);

  const vacancyInfos = useMemo(() => {
    const infos: CandidateInviteEmailTemplateVacancyInfo[] = [];

    if (locationStr)
      infos.push({
        key: 'location',
        icon: <ConsLocationIcon color="primary" sx={{ fontSize: '14px' }} />,
        label: locationStr,
      });

    if (educationStr)
      infos.push({
        key: 'education',
        icon: <ConsSchoolIcon color="primary" sx={{ fontSize: '14px' }} />,
        label: educationStr,
      });

    if (salaryStr)
      infos.push({
        key: 'salary',
        icon: <ConsGajiIcon color="primary" sx={{ fontSize: '14px' }} />,
        label: salaryStr,
      });

    return infos;
  }, [locationStr, educationStr, salaryStr]);

  return (
    <Box
      data-test-id="ctCandidateInviteEmail"
      boxShadow="0px 12px 40px 0px rgba(0, 0, 0, 0.15)"
      borderRadius="16px"
      maxWidth="375px"
      width="100%"
      p={3}
      {...props}
    >
      <Stack gap={2}>
        {/* Header */}
        <Stack alignItems="center" justifyContent="center">
          <Image
            data-test-id="imgCandidateInviteEmailKitaLulusLogo"
            src="/kitalulus-logo.svg"
            alt="KitaLulus"
            sx={{ width: '100px' }}
          />
        </Stack>

        {/* Content */}
        <Stack gap={2} alignItems="center" justifyContent="center">
          <Image
            data-test-id="imgCandidateInviteEmailIllustration"
            src="/illustrations/candidate-invitation-email-illustration.webp"
            alt="Candidate Invite"
            sx={{ width: '200px' }}
          />
          <Typography
            data-test-id="lbCandidateInviteEmailTitle"
            variant="body1"
            fontSize="20px"
            fontWeight={700}
            lineHeight="28px"
            textAlign="center"
            color="#424242"
          >
            {t('candidate:emailInvitationPreview.title', {
              companyName: company.name,
            })}
          </Typography>
          <Box
            data-test-id="ctCandidateInviteEmailContent"
            bgcolor="#EDF5FD"
            p={2}
            alignSelf="stretch"
            borderRadius={1.5}
          >
            <Stack gap={2}>
              <Typography
                data-test-id="lbCandidateInviteEmailContent"
                component="div"
                fontSize="14px"
                fontStyle="italic"
                lineHeight="22px"
                color="#424242"
                whiteSpace="pre-wrap"
                sx={{ a: { color: brandColors.info[500] } }}
                dangerouslySetInnerHTML={{ __html: formatTemplateContent }}
              />

              {/* Vacancy info */}
              <Box
                data-test-id="ctCandidateInviteEmailVacancyInfo"
                bgcolor="#fdfdfd"
                p={2}
                borderRadius="8px"
              >
                <Stack gap={2}>
                  <Stack direction="row" gap={1.25} alignItems="flex-start">
                    <Image
                      data-test-id="imgCandidateInviteEmailCompanyLogo"
                      src={company.logoURL ?? '/icon/cons-company.svg'}
                      alt="Company"
                      sx={{
                        flexShrink: 0,
                        width: '45px',
                        height: '45px',
                        borderRadius: '100px',
                        objectFit: 'cover',
                      }}
                    />
                    <Stack gap={0.25} pt={0.4}>
                      <Typography
                        data-test-id="lbCandidateInviteEmailPositionName"
                        fontFamily="Source Sans Pro"
                        fontSize="14px"
                        fontWeight={600}
                        lineHeight="18px"
                        color="#424242"
                      >
                        {vacancy.positionName}
                      </Typography>
                      <Typography
                        data-test-id="lbCandidateInviteEmailCompanyName"
                        fontFamily="Source Sans Pro"
                        fontSize="14px"
                        lineHeight="18px"
                        color="#424242"
                      >
                        {company.name}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack gap={0.5}>
                    {vacancyInfos.map((info, i) => (
                      <Stack
                        key={info.key}
                        data-test-id={`ctCandidateInviteEmailInfo[${i}]`}
                        direction="row"
                        gap={1}
                      >
                        {info.icon}
                        <Typography
                          data-test-id={`lbCandidateInviteEmailInfo[${i}]`}
                          fontFamily="Source Sans Pro"
                          fontSize="12px"
                          color="#424242"
                          lineHeight="15px"
                        >
                          {info.label}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>

        {/* Footer */}
        <Stack alignItems="center" justifyContent="center">
          <Box
            data-test-id="btnCandidateInviteEmailSee"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="44px"
            bgcolor="#1E88E5"
            color="#fff"
            fontSize="16px"
            fontWeight={700}
            fontFamily="Source Sans Pro"
            lineHeight="20px"
            textAlign="center"
            borderRadius="6px"
            sx={{ cursor: 'pointer', userSelect: 'none' }}
          >
            {t('candidate:emailInvitationPreview.button')}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CandidateInviteEmailTemplate;
