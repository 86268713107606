import {
  Avatar,
  Box,
  DashboardHeader,
  Divider,
  Label,
  MenuItem,
  MenuList,
  MenuPopover,
  NavList,
  NavListSimpleItem,
  Sidebar,
  Stack,
  Typography,
  useCollapseDrawer,
  useTheme,
  useMediaQuery,
  brandColors,
} from '@kitalulus/web-ui-kit';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import AccountStatus from '~/components/AccountStatus';
import PremiumBannerSquare from '~/components/PremiumBannerSquare';
import Apartment from '~/components/Icons/Apartment';
import Dashboard from '~/components/Icons/Dashboard';
import Help from '~/components/Icons/Help';
import MenuOpenIcon from '~/components/Icons/MenuOpenIcon';
import PrivacyTip from '~/components/Icons/PrivacyTip';
import UserGroup from '~/components/Icons/UserGroup';
import WorkIcon from '~/components/Icons/WorkIcon';
import { useCompanyAccManagerWhatsappLink } from '~/hooks/use-company';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setDialog } from '~/store/views/dialogs-slice';
import { setSideMenu } from '~/store/views/side-menu-slice';
import { Nullable } from '~/types';
import Analytics, { ANALYTICS_EVENTS } from '~/utils/analytics';
import VerifiedIcon from '../Icons/VerifiedIcon';
import { LayoutMenu, LayoutProps } from './Layout.types';
import * as mpe from '~/utils/mixpanel';
import {
  MENU_NAMES,
  MIXPANEL_ACCOUNT_FAQ_LINK_POSITION,
  MIXPANEL_ACCOUNT_HELP_LINK_POSITION,
} from '~/utils/constants/mixpanel';
import BannerFreeTrial from '../BannerFreeTrial/BannerFreeTrial';
import { FiSsHeadset } from '../Icons/FiSsHeadset';
import { kitalulusLink } from '~/utils/kitalulus-link';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import AutoAwesomeIcon from '~/components/Icons/AutoAwesomeIcon';

const Layout = ({ children }: LayoutProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isCandidateRecommendationV2Active = useFeatureFlag(
    'candidate_recommendation_v2',
  );

  // view states
  const { isCollapse } = useCollapseDrawer();
  const [anchorElm, setAnchorElm] = useState<Nullable<HTMLElement>>(null);
  const isPayPerPostActive = useFeatureFlag('pay_per_post');

  const menuIcon: Record<string, ReactNode> = {
    dashboard: <Dashboard />,
    vacancy: <WorkIcon />,
    applicants: <UserGroup />,
    account: <Apartment />,
    privacy: <PrivacyTip />,
    help: <Help />,
    candidaterecommendation: isCandidateRecommendationV2Active ? (
      <AutoAwesomeIcon />
    ) : (
      <VerifiedIcon />
    ),
  };

  const {
    menus,
    company,
    user,
    applicantCount,
    sideMenu,
    bannerFreeTrial,
    realtimeData,
  } = useAppSelector((state) => state);

  const waLink = useCompanyAccManagerWhatsappLink('dashboard');

  const menuTypeObj: Record<string, any> = {
    [MENU_NAMES.DASHBOARD]: 'dashboard',
    [MENU_NAMES.VACANCY]: 'vacancy',
    [MENU_NAMES.APPLICANTS]: 'applicant',
    [MENU_NAMES.CANDIDATE_RECOMMENDATION]: 'candidate',
    [MENU_NAMES.ACCOUNT]: 'account',
  };

  const mapMenus = useMemo(() => {
    const menuInfoById: Record<string, any> = {};
    if (applicantCount?.menuId)
      menuInfoById[applicantCount.menuId] = applicantCount.countStr;

    // server menus
    const mapServerMenus: LayoutMenu[] = menus.map((m) => {
      const isMenuActive =
        sideMenu.hasActiveMenu && !!(m.path && pathname.includes(m.path));
      return {
        name: m.name ? m.name.toLowerCase() : '',
        label: m.displayName ?? '',
        icon: (m.name && menuIcon[m.name.toLowerCase()]) ?? undefined,
        isActive: isMenuActive,
        onClick: () => {
          m.name &&
            mpe.menuSidebarSelect({
              menuSelect: menuTypeObj[m.name],
            });
          navigate(m.path ?? '/');
        },
        info: m.id && menuInfoById[m.id],
      };
    });

    // additional menus
    const additionalMenus: LayoutMenu[] = [
      {
        name: 'help-center',
        label: t('common:helpCenter'),
        icon: menuIcon.help,
        onClick: () => {
          mpe.accountFaq({
            linkPosition: MIXPANEL_ACCOUNT_FAQ_LINK_POSITION.SIDE_BAR,
          });
          window.open(kitalulusLink.helpCenter, '_blank');
        },
      },
    ];

    return [...mapServerMenus, ...additionalMenus];
  }, [menus, pathname, applicantCount, sideMenu.hasActiveMenu]);

  const hasBadge = useMemo(() => {
    return !!mapMenus.find(({ info }) => !!info);
  }, [mapMenus]);

  const profileMenus = useMemo(() => {
    return [
      {
        name: 'CompanyAccount',
        label: t('common:companyAccount'),
        onClick: () => navigate('/account'),
      },
      {
        name: 'CompanyLogout',
        label: t('common:logout'),
        onClick: () =>
          dispatch(
            setDialog({
              logoutConfirmationDialog: {
                open: true,
                content: { logoutLocation: 'top navbar' },
              },
            }),
          ),
      },
    ];
  }, []);

  const packageName = useMemo(() => {
    if (isPayPerPostActive)
      return (
        realtimeData.packageByCompanyId.data?.currentHighestPackageName ?? ''
      );
    return realtimeData?.company?.data?.package?.name ?? '';
  }, [realtimeData]);

  return (
    <>
      <BannerFreeTrial />
      <Stack position="relative">
        <DashboardHeader
          showBadge={hasBadge}
          onOpenSidebar={() => dispatch(setSideMenu({ isOpen: true }))}
          sx={{ mt: bannerFreeTrial.height + 'px' }}
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <Stack
              className="menu-help"
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                mpe.accountHelp({
                  linkPosition: MIXPANEL_ACCOUNT_HELP_LINK_POSITION.DASHBOARD,
                });
                Analytics.sendEvent(ANALYTICS_EVENTS.OTHER_HELP);
                window.open(waLink, '_blank');
              }}
            >
              <FiSsHeadset sx={{ color: 'text.secondary' }} />
              {!isMobile && (
                <Typography variant="body1" color="text.secondary">
                  {t('common:contactKitalulus')}
                </Typography>
              )}
            </Stack>

            <Avatar
              data-test-id="btnDashboardHeaderAvatar"
              className="account-avatar"
              src={(company?.logo?.url || company?.logoUrl) ?? ''}
              displayName={company?.displayName ?? ''}
              isOpen={!!anchorElm}
              onOpen={(e) => setAnchorElm(e.currentTarget)}
            />

            {/* Profile menus */}
            <MenuPopover
              open={!!anchorElm}
              anchorEl={anchorElm}
              onClose={() => setAnchorElm(null)}
              disableScrollLock
            >
              <MenuList>
                <Box
                  data-test-id="ctDashboardHeaderCompany"
                  sx={{ my: 0.5, px: 2.5 }}
                >
                  <Typography
                    data-test-id="lbDashboardHeaderCompanyName"
                    variant="subtitle2"
                    noWrap
                  >
                    {company?.displayName ?? 'Company Name'}
                  </Typography>
                  <Typography
                    data-test-id="lbDashboardHeaderCompanyEmail"
                    variant="body2"
                    sx={{ color: 'text.secondary' }}
                    noWrap
                  >
                    {user?.username ?? 'company@email.com'}
                  </Typography>
                </Box>
                <Divider sx={{ borderStyle: 'solid' }} />
                {profileMenus.map((p) => (
                  <MenuItem
                    key={p.label}
                    data-test-id={`mnDashboardHeader${p.name}`}
                    onClick={() => {
                      p.onClick();
                      // close menu
                      setAnchorElm(null);
                    }}
                    children={p.label}
                  />
                ))}
              </MenuList>
            </MenuPopover>
          </Stack>
        </DashboardHeader>

        <Sidebar
          data-test-id="ctDashboardSidebar"
          logoDesktop="/kitalulus-logo.svg"
          isOpenSidebar={sideMenu.isOpen}
          onCloseSidebar={() => dispatch(setSideMenu({ isOpen: false }))}
          menuIcon={<MenuOpenIcon sx={{ color: brandColors.grey[600] }} />}
          sx={{
            mt: bannerFreeTrial.height + 'px',
            height: `calc(100vh - ${bannerFreeTrial.height}px)`,
          }}
        >
          <Stack justifyContent="space-between" height="calc(100% - 81px)">
            <Stack gap={1} py={1}>
              <AccountStatus
                avatarOnly={isCollapse}
                avatarSrc={(company?.logo?.url || company?.logoUrl) ?? ''}
                displayName={company?.displayName ?? ''}
                status={
                  realtimeData?.company?.data?.company?.status ?? undefined
                }
                statusDisplayName={company?.verificationStatusDisplayStr ?? ''}
                statusDisplayNotice={company?.verificationStatusNotice ?? ''}
                packageName={packageName}
              />

              {/* Sidebar menu list */}
              <NavList isMinimized={isCollapse} sx={{ flex: 1, p: 0 }}>
                {mapMenus.map((m, i) => (
                  <NavListSimpleItem
                    key={m.label}
                    data-test-id={`mnDashboardSidebar[${i}]`}
                    className={`side-nav-${m.name}`}
                    onClick={() => {
                      if (m.onClick) m.onClick();
                      // close sidebar
                      dispatch(setSideMenu({ isOpen: !sideMenu.isOpen }));
                    }}
                    label={m.label}
                    icon={m.icon}
                    isActive={m.isActive}
                    info={
                      m.info && (
                        <Label
                          data-test-id={`mnDashboardSidebarBadge[${i}]`}
                          color="primary"
                          variant="outlined"
                        >
                          {m.info}
                        </Label>
                      )
                    }
                  />
                ))}
              </NavList>
            </Stack>
            <PremiumBannerSquare />
          </Stack>
        </Sidebar>
        {children}
      </Stack>
    </>
  );
};

export default Layout;
