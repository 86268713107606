import {
  Dialog,
  Stack,
  Typography,
  DialogContent,
  Paper,
  useTheme,
  Box,
  useMediaQuery,
  Divider,
  brandColors,
  Tabs,
  Tab,
  Badge,
} from '@kitalulus/web-ui-kit';
import { Skeleton } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CloseIcon from '~/components/Icons/CloseIcon';
import { useCandidateRecommendationDetail } from '~/hooks/candidate-recommendation';
import { useAppSelector } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';
import { APPLICANT_TAB_PRIMARY, FETCH_STATUS } from '~/utils/constants';
import ApplicantActions from './ApplicantActions';
import CandidateRecommendationActions from './CandidateRecommendationActions';
import { ProfilePreviewDialogProps } from './ProfilePreviewDialog.types';
import { useApplicantDetail } from '~/hooks/applicants';
import { useApplicantDetailFetch } from '~/hooks/applicant-detail';
import { PROFILE_VARIANTS, PROFILE_LOCATION } from '~/utils/constants/profile';
import AvatarContainer from '~/components/ProfileDetail/AvatarContainer';
import { useApplicantPageQuery } from '~/hooks/page-query/use-applicant-page-query';
import { clearApplicantDetail } from '~/store/features/applicant-detail-slice';
import { APPLICANT_LIST_PREVIEW_TAB } from '~/utils/constants/applicant-list';
import TruncateText from '~/components/TruncateText';
import LocationIcon from '~/components/Icons/LocationIcon';
import CloneProps from '~/components/CloneProps';
import ScreeningQuestionAnswers from '~/pages/Applicants/ScreeningQuestionAnswers';
import * as mpe from '~/utils/mixpanel';
import { convertDateToISOString } from '~/utils/helper';

import ProfilePreview from './ProfilePreview';
import TabTooltip from './TabTooltip';
import { setApplicantsDisplayData } from '~/store/features/applicants';
import { useRemoteConfigValue } from '~/hooks/firebase/use-remote-config';
import ResponseCandidateStatus from '~/components/ProfileDetail/ResponseCandidateStatus';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import { EpJobVacancyCandidateStatus } from '~/types/graphql/graphql';

const ProfilePreviewDialog = ({
  content,
  ...props
}: ProfilePreviewDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [urlParams] = useApplicantPageQuery();
  const dispatch = useDispatch();
  const isChangeIsReadActive = useRemoteConfigValue(
    'ff_applicant_change_is_read_status',
  );
  const isCandidateRecommendationV2Active = useFeatureFlag(
    'candidate_recommendation_v2',
  );

  // Applicant
  const { queryApplicantDetail } = useApplicantDetail();
  const { updateApplicantAsRead } = useApplicantDetailFetch(false, false);

  const { applicantDetail, applicants, vacancyDetail } = useAppSelector(
    (state) => state,
  );
  const {
    data: dataApplicantDetail,
    fetchStatusDetail: applicantDetailStatus,
  } = applicantDetail;
  const { applicantPreview, displayData } = applicants;

  const isPremium =
    urlParams.active_tab_primary === APPLICANT_TAB_PRIMARY.AI_RECOMMENDATION ||
    content?.variant === PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION;

  // Candidate Recommendation
  const { queryCandidateDetail } = useCandidateRecommendationDetail();
  const { candidateRecommendationDetail } = useAppSelector((state) => state);
  const {
    data: dataCandidateRecommendationDetail,
    fetchStatus: candidateRecommendationStatus,
  } = candidateRecommendationDetail.detail;

  const isShowResponseCandidateStatus =
    isCandidateRecommendationV2Active &&
    dataCandidateRecommendationDetail?.status ===
      EpJobVacancyCandidateStatus.InvitationSent;

  const handleCloseDialog = () => {
    dispatch(clearDialog('profilePreviewDialog'));
    dispatch(clearApplicantDetail());
    content?.doChangeTab?.(APPLICANT_LIST_PREVIEW_TAB.PROFILE_PREVIEW);
    content?.onClose?.({
      openFreePlanDialog: content?.showFreePlanLimitationOnClose,
    });
  };

  const updateIsReadAndRefetchList = useCallback(async () => {
    if (dataApplicantDetail?.id && !dataApplicantDetail?.isRead) {
      const response = await updateApplicantAsRead({
        variables: {
          data: {
            id: dataApplicantDetail?.id,
          },
        },
      });

      // Optimistically change isRead to true
      if (response?.data?.updateJobApplicationIsRead?.isSuccess) {
        const manipulatedDisplayData = displayData.map((data) =>
          data?.id === dataApplicantDetail?.id
            ? {
                ...data,
                isRead: true,
              }
            : data,
        );
        dispatch(setApplicantsDisplayData(manipulatedDisplayData));
      }
    }
  }, [dataApplicantDetail]);

  useEffect(() => {
    if (!content?.profileId) return;

    if (content.variant === PROFILE_VARIANTS.APPLICANT) {
      queryApplicantDetail(content.profileId);
    } else if (content.variant === PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION) {
      queryCandidateDetail(content.profileId);
    }
  }, [content?.profileId, content?.variant]);

  useEffect(() => {
    if (
      isChangeIsReadActive &&
      applicantDetailStatus === FETCH_STATUS.RESOLVED
    ) {
      updateIsReadAndRefetchList();
    }
  }, [isChangeIsReadActive, applicantDetailStatus]);

  if (!content) return null;

  const lookUpData = {
    [PROFILE_VARIANTS.APPLICANT]: dataApplicantDetail,
    [PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION]:
      dataCandidateRecommendationDetail,
  };
  const profileData = lookUpData[content.variant];

  const lookUpLoading = {
    [PROFILE_VARIANTS.APPLICANT]: applicantDetailStatus,
    [PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION]: candidateRecommendationStatus,
  };
  const profileLoading = lookUpLoading[content.variant];

  const isShowShimmer = [FETCH_STATUS.IDLE, FETCH_STATUS.PENDING].includes(
    profileLoading,
  );

  const handleChangeTab = (value: APPLICANT_LIST_PREVIEW_TAB) => {
    if (value === APPLICANT_LIST_PREVIEW_TAB.SCREENING_QUESTIONS) {
      mpe.applicantScreeningPreviewTab({
        vacancyCode: vacancyDetail?.code ?? '',
        vacancyTitle: vacancyDetail?.positionName ?? '',
        vacancyCloseDate: convertDateToISOString(vacancyDetail?.closeDate),
      });
    }
    content?.doChangeTab?.(value);
  };

  return (
    <Dialog
      {...props}
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          minHeight: isMobile ? 'unset' : 'calc(100% - 64px)',
          padding: 0,
          ...(isMobile && {
            position: 'absolute',
            bottom: 0,
            width: 1,
            margin: 0,
          }),
        },
      }}
    >
      <Stack direction="row" px={2} pt={1}>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
        >
          {isMobile && (
            <Divider
              sx={{
                width: 85,
                borderWidth: 2,
                borderColor: 'text.primary',
                position: 'relative',
              }}
            />
          )}
        </Box>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleCloseDialog} />
      </Stack>
      {/* container */}
      <DialogContent
        sx={{ pb: { xs: 2, sm: 0 }, px: { xs: 1, sm: 4 }, py: { sm: 5 } }}
      >
        <Stack gap={2}>
          <Paper
            sx={{
              borderRadius: theme.spacing(2),
              boxShadow: theme.customShadows['card'],
              border: `1px solid ${brandColors.neutral[400]}`,
            }}
          >
            <Stack
              direction="row"
              gap={4}
              alignItems="start"
              sx={{
                p: 3,
              }}
            >
              <Stack alignItems="center" position="relative">
                <AvatarContainer
                  data-test-id="imgApplicantDetailAvatar"
                  imgUrl={profileData?.userProfile?.imageUrl ?? ''}
                  displayName={profileData?.userProfile?.name ?? ''}
                  matchingResult={content.matchingResult}
                  isMobile={isMobile}
                  showShimmer={isShowShimmer}
                  showKitaMatch={isPremium}
                  imgProps={{
                    id: 'imgApplicantPreviewAvatar',
                    fetchpriority: 'high',
                  }}
                />
              </Stack>
              <Stack gap={isPremium ? 1 : 2.25} flex={1}>
                <Stack
                  direction="row"
                  alignItems={isMobile ? 'flex-start' : 'center'}
                  justifyContent={isMobile ? 'flex-start' : 'space-between'}
                  gap={2.5}
                  position="relative"
                >
                  <Stack
                    direction={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    justifyContent="flex-start"
                    gap={{ sm: 0, md: 1 }}
                    width="100%"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      width="100%"
                    >
                      {isShowShimmer && (
                        <Stack>
                          <Skeleton
                            variant="text"
                            sx={{
                              width: { xs: 70, sm: 150 },
                              height: { xs: 30, sm: 36 },
                            }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{
                              width: { xs: 100, sm: 180 },
                            }}
                          />
                        </Stack>
                      )}
                      {profileLoading === FETCH_STATUS.RESOLVED && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          gap={1}
                        >
                          <Stack>
                            <Stack direction="row">
                              {!isShowResponseCandidateStatus && (
                                <>
                                  <TruncateText
                                    data-text-id="lbApplicantDetailName"
                                    noOfLines={1}
                                  >
                                    <Typography variant="h4">
                                      {profileData?.userProfile?.name},
                                    </Typography>
                                  </TruncateText>
                                  <Box
                                    data-text-id="lbApplicantDetailAge"
                                    sx={{ minWidth: 60 }}
                                  >
                                    <Typography variant="h4">
                                      {profileData?.userProfile?.age}
                                    </Typography>
                                  </Box>
                                </>
                              )}

                              {isShowResponseCandidateStatus && (
                                <Typography variant="h4">
                                  <TruncateText
                                    data-text-id="lbApplicantDetailName"
                                    maxCharacterLength={60}
                                    showLastChars={4}
                                  >
                                    {`${profileData?.userProfile?.name}, ${profileData?.userProfile?.age}`}
                                  </TruncateText>
                                </Typography>
                              )}
                            </Stack>

                            <Stack
                              direction="row"
                              spacing={0.5}
                              color={brandColors.neutral[700]}
                              fontSize="12px"
                              alignItems="center"
                            >
                              <LocationIcon
                                sx={{
                                  color: brandColors.danger[500],
                                  fontSize: '20px',
                                }}
                              />
                              <TruncateText noOfLines={1}>
                                <Typography fontSize="inherit">
                                  {profileData?.userProfile?.city?.name ??
                                    undefined ??
                                    '-'}
                                </Typography>
                              </TruncateText>
                              <Box alignSelf="stretch" py={0.6}>
                                <Divider
                                  orientation="vertical"
                                  sx={{ bgcolor: brandColors.neutral[600] }}
                                />
                              </Box>
                              <TruncateText noOfLines={1}>
                                <Typography fontSize="inherit">
                                  {profileData?.userProfile?.district?.name
                                    ? `${t('common:districtPrefix')} ${
                                        profileData?.userProfile?.district?.name
                                      }`
                                    : '-'}
                                </Typography>
                              </TruncateText>
                            </Stack>
                          </Stack>

                          {isShowResponseCandidateStatus && !isMobile && (
                            <Stack>
                              <ResponseCandidateStatus
                                item={dataCandidateRecommendationDetail}
                                vacancyCode={vacancyDetail?.code ?? ''}
                                isDisableTooltip
                              />
                            </Stack>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                {!isMobile && (
                  <>
                    {isShowShimmer && (
                      <Stack>
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="90%" />
                      </Stack>
                    )}
                    {profileLoading === FETCH_STATUS.RESOLVED && (
                      <Typography
                        data-test-id="lbApplicationDetailAbout"
                        variant="body2"
                        sx={{
                          height: 110,
                          overflow: 'auto',
                          wordBreak: 'break-word',
                          textAlign: 'justify',
                          pr: 1.5,
                        }}
                      >
                        {profileData?.userProfile?.about ?? ''}
                      </Typography>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
            {isMobile && (
              <>
                {isShowShimmer && (
                  <Stack pt={2} p={3}>
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                  </Stack>
                )}
                {isShowResponseCandidateStatus && (
                  <Stack px={3}>
                    <ResponseCandidateStatus
                      item={dataCandidateRecommendationDetail}
                      vacancyCode={vacancyDetail?.code ?? ''}
                      isDisableTooltip
                    />
                  </Stack>
                )}
                {profileLoading === FETCH_STATUS.RESOLVED && (
                  <Stack pt={2} p={3}>
                    <Typography
                      data-test-id="lbApplicationDetailAbout"
                      variant="body2"
                      sx={{
                        height: 110,
                        wordBreak: 'break-word',
                        overflow: 'auto',
                        textAlign: 'justify',
                        pr: 1.5,
                      }}
                    >
                      {profileData?.userProfile?.about ?? ''}
                    </Typography>
                  </Stack>
                )}
              </>
            )}
            <Stack>
              {/* Tabs */}
              {content.tabs !== null && (
                <Box>
                  <Tabs
                    textColor="inherit"
                    indicatorColor="primary"
                    variant="scrollable"
                    value={applicantPreview.tab}
                    onChange={(
                      _: SyntheticEvent<Element, Event>,
                      value: APPLICANT_LIST_PREVIEW_TAB,
                    ) => handleChangeTab(value)}
                    sx={{
                      color: theme.palette.text.primary,
                      px: 3,
                    }}
                  >
                    {content?.tabs?.map((tabProps) => (
                      <CloneProps key={tabProps.value} {...tabProps}>
                        {({
                          haveNewLabel,
                          showTooltip,
                          tooltipContext,
                          ...tabProps
                        }) => (
                          <Badge
                            color="secondary"
                            badgeContent={
                              haveNewLabel ? `${t('common:new')}!` : 0
                            }
                            sx={{
                              textTransform: 'uppercase',
                              '& .MuiBadge-badge': {
                                top: 10,
                                right: -13,
                              },
                            }}
                          >
                            <TabTooltip
                              show={showTooltip ?? false}
                              tooltipContext={tooltipContext}
                            >
                              <Tab
                                iconPosition="start"
                                {...tabProps}
                                sx={{
                                  fontWeight: 600,
                                  px: 1.5,
                                  textTransform: 'none',
                                }}
                              />
                            </TabTooltip>
                          </Badge>
                        )}
                      </CloneProps>
                    ))}
                  </Tabs>
                </Box>
              )}
            </Stack>
          </Paper>
          {applicantPreview?.tab ===
            APPLICANT_LIST_PREVIEW_TAB.PROFILE_PREVIEW && (
            <ProfilePreview
              isShowShimmer={isShowShimmer}
              profileLoading={profileLoading}
              content={content}
            />
          )}
          {applicantPreview?.tab ===
            APPLICANT_LIST_PREVIEW_TAB.SCREENING_QUESTIONS && (
            <ScreeningQuestionAnswers location={PROFILE_LOCATION.PREVIEW} />
          )}
        </Stack>
      </DialogContent>
      {content.variant === PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION ? (
        <CandidateRecommendationActions
          isShowResponseCandidateStatus={isShowResponseCandidateStatus}
          handleCloseDialog={handleCloseDialog}
          handleViewDetail={content.doViewDetail}
          showAcceptReject={
            content.showCandidateRecommendationAcceptReject ?? false
          }
        />
      ) : (
        <ApplicantActions
          handleCloseDialog={handleCloseDialog}
          doViewDetail={content?.doViewDetail}
        />
      )}
    </Dialog>
  );
};

export default ProfilePreviewDialog;
